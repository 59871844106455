import { TypographyOptions } from '@mui/material/styles/createTypography';
import { lightModeColors } from '../themeColors';
// New fonts imported in the index.html file inside the head tag

// introduce new typography variant to be used for poster text
// declare module '@mui/material/styles' {
//   interface TypographyVariants {
//     poster: React.CSSProperties;
//   }
//   interface TypographyVariantsOptions {
//     poster?: React.CSSProperties;
//   }
// }
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headerText: true;
    subHeaderText: true;
  }
}

export const typography: TypographyOptions = {
  fontFamily: ['"Urbanist","Barlow", "sans-serif","Inter"'].join(','),
  htmlFontSize: 16,
  fontSize: 13.333,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    fontSize: 64,
    fontFamily: 'Urbanist',
  },
  h2: {
    fontWeight: 800,
    fontFamily: 'Urbanist',
    fontSize: 55,
  },
  h3: {
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: 0.4,
    fontFamily: 'Urbanist',
  },
  h4: {
    fontFamily: 'Urbanist',
    fontWeight: 800,
    fontSize: 32,
    letterSpacing: 0.32,
  },
  h5: {
    fontFamily: 'Urbanist',
    fontWeight: 800,
    fontSize: 24,
    letterSpacing: 0.24,
  },
  h6: {
    fontSize: 20,
    letterSpacing: 0.2,
    fontWeight: 800,
    fontFamily: 'Urbanist',
  },
  body1: {
    fontFamily: 'Urbanist',
    letterSpacing: 0.14,
    fontSize: 14,
  },
  body2: {
    fontFamily: 'Urbanist',
    letterSpacing: 0.12,
    fontSize: 12,
  },
  button: {
    fontWeight: 600,
  },
  caption: {
    fontSize: 13,
    textTransform: 'uppercase',
  },
  subtitle1: {
    fontSize: 14,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: 15,
  },
  overline: {
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  headerText: {
    fontWeight: 800,
    fontSize: '2.25rem',
    fontFamily: 'Urbanist',
    color: lightModeColors.primary.main || '#000000',
  },
  subHeaderText: {
    fontSize: 20,
    letterSpacing: 0.2,
    fontWeight: 800,
    fontFamily: 'Urbanist',
    color: lightModeColors.primary.main || '#000000',
  },
};
